import * as React from 'react'
import Layout from '../../components/layout'
import DWChart from 'react-datawrapper-chart'
import { ExplanatoryText } from '../../components/scrolly'
import EmailSubscribe from '../../components/email-subscribe'

const ElectricityEmissionsByState = () => {

  return (
    <Layout
      title={"How Dirty Is Your Electricity"}
      description={"A data visualization exploring electricity emissions in each state."}
      image={"/images/article-images/electricity-emissions-by-state.png"}
    >
      
      <section id="hero" className='mt-8 max-w-3xl mx-auto'>
        <div className='flex flex-col items-center pt-8 sm:pb-4 px-4'>
          <h1 className='text-4xl lg:text-5xl text-center'>How dirty is your electricity?</h1>
          <p className='mt-2 font-normal text-xl sm:text-2xl text-center'>A data visualization exploring electricity emissions in each state.</p>
        </div>
      </section>

      <section id='data-viz' className='max-w-4xl mx-auto pb-12'>
        <h2 className='px-4 mb-2 text-xl text-center'>Total electricity emissions vs. per capita electricity emissions (2020)</h2>
        <div className='px-4'>
          <DWChart title="grid-emissions-intensity" src="https://datawrapper.dwcdn.net/pOLLw/1/"></DWChart>
        </div>
      </section>

      <ExplanatoryText>
        <p>This data visualization is a part of a larger project exploring electricity emissions in America.</p>
          
        <p>If you want to receive an email when we publish the full story, sign up for our newsletter below. </p>
      </ExplanatoryText>

      <section id="newsletter-cta" className='bg-slate-100'>
        <div className="text-center flex flex-col mt-12 pt-6 pb-12 px-3 sm:px-3 md:px-6 lg:px-12">
            <div className="mx-auto max-w-2xl">
              <h4>Sign up to receive the next story in your inbox</h4>
              <p>
                Every week we write a newsletter packed with all kinds of climate data, research, and insights. 
                We think you'll love it.
              </p>
            </div>
            <EmailSubscribe />
        </div>
      </section>

    </Layout>
  )
}

export default ElectricityEmissionsByState